/*
 * This file is intended to be imported for start page related utilities and variables.
 *
 * These variables are named generically. Do not use two page imports on the same SCSS module.
 */

// FORM STYLE
$input-height: 38px;

$h1-size: em(24px);
$h2-size: em(18px);
$p-size: em(14px);
