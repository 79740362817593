.tab-title {
  font-size: 8px;
}

@media (min-width: 320px) {
  .tab-title {
    font-size: 10.5px;
  }
}

@media (min-width: 375px) {
  .tab-title {
    font-size: 12px;
  }
}

@media (min-width: 576px) {
  .tab-title {
    font-size: 10.5px;
  }
}

@media (min-width: 992px) {
  .tab-title {
    font-size: 12px;
  }
}
