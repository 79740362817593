.contact-submit {
    vertical-align: middle;
    text-align: center;
    padding: 8px 30px;
    background: $themeGreen;
    color: #fff!important;
    border: 1px solid $themeGreen;
    border-radius: 3px;
    font-size: 18px;
    cursor: pointer;
    font-family: ProximaNovaRegular,sans-serif;
    text-rendering: optimizelegibility;
    -webkit-font-smoothing: antialiased;
    text-transform: capitalize;
  }
  .unpakt-field, .unpakt-textbox {
    height: 34px;
    width: 250px;
    font-size: 13px;
    color: #042b3b;
    background: #fff;
    border: 1px solid #819ca1 !important;
    padding: 8px 14px;
    box-shadow: none;
    border-radius: 2px;
    font-family: "ProximaNovaRegular", sans-serif;
    margin: 0 0 15px 0;
  }
  .unpakt-textarea {
      min-height: 100px;
      background-color: white;
      border: 1px solid #819ca1 !important;
  }
  .term-conditions {
    line-height: 32px;
    h1 {
      font-size: 30px;
      font-weight: 600;
    }
    h2 {
      font-size: 20px;
      font-weight: 600;
    }
    ol {      
      font-size: 16px;
      padding-left: 15px;
    }
  }
  .term-order-list {
    li {
      line-height: 20px;
      margin-top: 20px;
    }
  }
  .term-link {
    text-decoration: underline;
    color: #0abbb5;
  }
  .term-text {
    line-height: 20px;
  }