.landing {
	margin-top: 10vh;
	position: relative;
}

.landing-title {
	margin-bottom: 26px;
	letter-spacing: -2px;
	animation: fadeIn ease 1s;
	h1 {
		font-weight: 500;
		line-height: 1;
		text-align: center;
	}
	span {
		color: $themeGreen;
	}
}
.landing-img__container {
	width: 100%;
	height: 60vh;
	background: url('/img/landingBody/hero-img--mobile.png') no-repeat center;
	background-size: contain;
	.landing-img__overlay {
		background: rgba(#2c2c2c, 0.2);
		height: 100%;
	}
}
@media (min-width: 768px) {
	.landing-img__container {
		width: 100%;
		height: 65vh;
		background: url('/img/landingBody/hero-img.png') no-repeat center;
		background-size: cover;
	}
	.getquotes__btn {
		width: 40vh !important; 
	}
}
@media (min-width: 768px) and (max-width: 1024px) {
	.instruction-div {
		flex-basis: 45% !important;
	}
	.getquotes__btn {
		width: 30vh !important; 
	}
}

@keyframes fadeIn {
	0% {
	  opacity:0;
	}
	100% {
	  opacity:1;
	}
}
@keyframes fadeOut {
	0% {
	  opacity:1;
	}
	100% {
	  opacity:0;
	}
}

.input-icon {
	width: 32px;
	height: 44px;
}
.geosuggest-input-Row {
	position: absolute;
	z-index: 1;
	display: flex;
	justify-content: flex-start;
	flex-direction: column;
	align-items: center;
	width: 100%;
	height: 300px;
	background-image: linear-gradient(to top, rgba(191, 191, 191, 0), rgb(255, 255, 255));
}
.getquotes__container {
	z-index: 1;
	margin-bottom: 5vh;
	display: flex;
	justify-content: center;
}
.getquotes__btn {
	background: #0abbb5;
	border: none;
	color: white; 
	width: 260px; 
	height: 5vh;
	border-radius: 8px;
}
.gradient-div {
	background-image: linear-gradient(to top, rgba(191, 191, 191, 0), white);
	position: absolute;
	z-index: 1;
	height: 45px;
	width: 100%;
}
@media (max-width: 600px) {
	.geosuggest-input-div {
		width: 80% !important;
	}
	.instruction-div {
		flex-basis: 82% !important;
	}
}
.geosuggest-input-div {
	width: 25%;
	display: flex;
	justify-content: flex-start;
	border: 1px solid $lightBorderGrey;
	background: white;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
	animation: fadeIn ease 1s;
	position: relative;
}

.geosuggest {
	display: flex;
	align-items: center;
}
.geosuggest__input-wrapper {
	width: 100%;
}
.geosuggest__input {
	border: white !important;
	height: 100%;
}

.instruction-Row {
	position: absolute;
	top: 56vh;
	display: flex;
	height: 200px;
	justify-content: center;
	align-items: center;
	// display: none;
	width: 100%;
	animation: fadeIn ease 2s;
}
.fixed {
	bottom: 0;	
}
.instruction-div {
	flex-basis: 25%;
	height: 110px;
	background: white;
	display: flex;
	align-items: center;
	justify-content: space-around;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.truck-img {
	height: 60px;
	padding-left: 10px;
}
.instruction-text {
	font-size: 14px;
	line-height: 0.1rem;
	padding-top: 25px;
	letter-spacing: -0.6px;
}
@media (max-width: 330px) {
	.instruction-text {
		font-size: 12px;
		letter-spacing: -0.7px;
	}
	.truck-img {
		height: 50px;
		padding-left: 7px;
	}
}

.map-marker {
	position: 'absolute';	
}
.marker-tool-tip {
	display: inline-block;    
    background-color: white;               
    font-size: 13px;
    font-weight: bolder;
}
.marker-tool-tip-text {
	padding-top: 1.2px;
	padding-left: 3px;
}
.marker-circle {
	width: 12px;
	height: 12px;
	background-color: $themeGreen;
	border-radius: 50%;
}

.tool-tip-container {
	width: 105px;
    display: flex;
    background: white;
	height: 22px;
	border-bottom: 1px solid grey;
	border-right: 1px solid grey;
	border-radius: 16px;
}

.geo-mapLayout {
	display: flex;
	justify-content: center;
	width: 100%;
	height: 60vh;
	transition: all 1s ease;	
}
.geo-mapLayout-65vh {
	height: 65vh;
}
.geo-mapLayout-55vh {
	height: 55vh;
}
.geo-mapLayout-40vh {
	height: 40vh;
}
.geo-mapLayout-35vh {
	height: 40vh;
}
@media (max-width: 560px) {
	.geo-mapLayout-35vh {
		height: 35vh;
	}
}
.hide-map {
	display: none;
}


