.details-header {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
    h1 {
        font-weight: bolder !important;
        letter-spacing: -1px !important;
    }
    p {
        font-weight: bolder;
    }
}

.physical-address-component {
    display: flex;
    flex-direction: column;
}

.full-address-container {
    text-align: start;
    color: $themeGreen;
    font-weight: bolder;
    letter-spacing: -1px;
    font-size: 17px;
}
.reschedule-button {
    border: none;
    border-radius: 4px;
    background-color: #e61919;
    color: white;
    height: 80%;
    font-size: 13px;
    letter-spacing: -0.4px;
    margin-right: 5px;
}

.reschedule-open {
    background-color: #0abbb5;
}
.reschedule-modal {
    margin-top: 200px;
}