// @import "./node_modules/pikaday/scss/pikaday";
// @import "./node_modules/angularjs-datepicker/src/css/angular-datepicker";

.pika-single {
	width: 240px;
	padding: 8px;
	border-radius: 4px;
	color: #000;

	.pika-table {
		td {
			border: 1px solid #bbb;
		}
	}

	.pika-label {
		font-size: 16px;
		line-height: 20px;
		vertical-align: top;
	}

	abbr, abbr[title] {
		cursor: default;
	}

	.pika-prev,
	.pika-next {
		width: 28px;
		height: 28px;
		background-color: transparent;
		background-size: 80%;
		opacity: 1;
		display: inline-block;

		&.is-disabled {
			cursor: default;
			opacity: 0.2;
			pointer-events: none;
		}
	}

	.pika-prev {
		// background-image: url('~/public/img/triangle-left.svg');
		background-position: center left;
	}

	.pika-next {
		// background-image: url('~/public/img/triangle-right.svg');
		background-position: center right;
	}

	.pika-button {
		font-size: 14px;
		padding: 7px;
    line-height: 14px;
		background: transparent;
		text-align: center;
		color: black;
    text-align: center;


		&:hover {
			background: #e2e2e2;
			transition: all 0.15s ease-in;
		}
	}

	.is-today {
		.pika-button {
			color: #41b8eb;
		}
	}

	.is-selected {
		.pika-button {
			box-shadow: none;
			background: #41b8eb;
			color: white;
			border-radius: 0;
		}
	}

	.is-disabled {
		.pika-button {
			color: #999;
		}
	}
}

.pika-lendar {
	margin: 0;
	width: auto;
	float: none;
}

.pika-static-parent {
	position: relative;
	.pika-container {
		position: absolute;
		z-index: 99999999;

		&.pika-left-field {
			left: 0;
		}

		&.pika-right-field {
			right: 0;
		}
	}
}
