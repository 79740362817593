.select-dropdown {
	width: 100%;
	position: relative;
	border-radius: 2px;

	.selection {
		cursor: pointer;
		overflow: hidden;
		vertical-align: middle;
		height: 100%;
		display: flex;
		justify-content: center;
		flex-direction: column;
		span {
			vertical-align: top;
			font-size: em(12px);
		}
		&:after {
			content: '▾';
			position: absolute;
			right: 9px;
			top: 0;
			font-size: em(16px);
		}
	}

	.dropdown {
		position: absolute;
		z-index: 99;
		width: 100%;
		background: linear-gradient(to bottom, #fff, #f9f9f9);
		border: 1px solid #eee;
		border-top-width: 0;
		display: none;
	}
	&.active {
		.dropdown {
			display: block;
		}
	}

	.dropdown-list {
		overflow-y: scroll;
		max-height: 200px;
		box-shadow: 0 0 3px #d6d6d6;
	}

	.dropdown-item {
		position: relative;
		transition: background .2s ease-in-out;
		border-bottom: 1px solid #e9e9e9;
		span > span {
			font-size: em(12px);
		}
		span {
			display: block;
			line-height: 14px;
			color: #000;
			&:before {
				content: "";
				display: block;
				width: 3px;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
				background-color: transparent;
				text-transform: none;
				transition: background-color .25s;
			}
			&:hover, :focus {
				background-color: #e2e3e5;
				text-decoration: none;
				&:before {
					background-color: #333333;
				}
			}
		}
	}

	@include grandparent('.start-view') {
		@import "../../variables/start";
		.selection {
			height: $input-height;
			border: 1px solid $input-border-color;
			background: #fff;
			&:after {
				line-height: $input-height;
			}
		}
		&.active {
			.selection {
				&:after {
					transform: rotateZ(180deg);
				}
			}
		}
	}

	@include grandparent('.moving-view-wrapper') {
		@import "../../variables/moving";
		.selection {
			height: $input-height;
			border: 1px solid $input-border-color;
			background-color: #f9fafb;
			&:after {
				line-height: $input-height;
			}
		}
		.dropdown-list {
			max-height: 200px;
		}
	}
}

.react-datepicker__triangle {
  
  left: 90%;
}
