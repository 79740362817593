.why-move-with-us__container {
  padding: 5vh 12vw;
}
.why-move-with-us-title__container {
  text-align: center;
  padding: 0 7vw;
}
.why-move-with-us__title {
  font-weight: bolder;
  font-size: 5vh;
  color: rgba(24, 24, 24, 0.829);
}
.why-move-with-us__subtitle {
  font-weight: 300;
  font-size: 2.4vh;
  line-height: 34px;
}
.img__container--desktop {
  margin-bottom: 3vh;
  img {
    width: 65px;
    height: 65px;
  }
}
.contents__title--desktop {
  font-weight: bolder;
  font-size: 3.0vh;
  text-align: center;
  margin-bottom: 0.8vh;
  color: rgba(24, 24, 24, 0.829);
}
.contents--desktop {
  font-size: 2.2vh;
  line-height: 20px;
  text-align: center;
  font-weight: 300;
  letter-spacing: -0.4px;
}

//////////////////////// Numbers ////////////////////////
.numbers__container {
  position: relative;
  background: #F6F6F6;
  margin: 0 0 7vh 0;
  padding: 8vh 10vw;
}
.numbers__row {
  display: flex;
  justify-content: center;
}
.number__block {
  display: flex;
  flex: 1;
  flex-direction: column;
  text-align: center;
  line-height: 3.8vh;
  .num {
    font-size: 3.0vh;
    color: #0abbb5;
    font-weight: bold;
    
  }
  .sub-text {
    font-size: 2.8vh;
    color: #979b9c;
  }
}
.powered_by_unpakt--landing--desktop {
  position: absolute;
  bottom: 1vh;
  right: 2vw;
  height: 3vh !important;
}

//////////////////////// How Move Now Works ////////////////////////
.body-content__title {
  &.desktop {    
    font-size: 5vh;
    color: rgba(24, 24, 24, 0.829);
  }
}
.how-move-now-works--desktop {
  font-weight: bolder;
  font-size: 3vh;  
  margin-bottom: 0.8vh;
  color: rgba(24, 24, 24, 0.829);
}
.body-content__subtitle {
  &.desktop {
    color: rgba(24, 24, 24, 0.829);
    font-size: 2.2vh;
    line-height: 30px;
    font-weight: 200;
  }
}

//////////////////////// What our customerss are saying ////////////////////////
.what-customer-saying__container {
  position: relative;
  background: #F6F6F6;
  margin-top: 2vh;
  padding: 6vh 6vw;
  .quote {
    position: absolute;
    right: 8vw;
    top: 4vh;
    width: 200px;
    height: 200px;
  }
}

.customer-saying__row {
  display: flex;
  margin-top: 8vh;
  padding: 5vh 0;
  justify-content: space-around;
}
.customer-saying__block {
  display: flex;
  flex-basis: 45%;
  flex-direction: column;
  align-items: flex-start;
  img {
    height: 3vh;
  }
  .name {
    font-weight: bolder;
    font-size: 2.2vh;
  }
}
.user-words__container--desktop {
  padding: 32px 35px 10px 0;
  p {
    font-size: 2.2vh;
    line-height: 30px;
    font-weight: 200;    
  }
}

//////////////////////// Other Services that we offer ////////////////////////
.other-services__container {
  display: flex;
  margin: 20vh 0;
}
.other-services__left__container {
  padding: 11vh 5vw;
  flex-basis: 75%;
  background: #F6F6F6;
  display: flex;
  justify-content: space-between;
  border-top-right-radius: 190px;
  border-bottom-right-radius: 190px;
  box-shadow: 0px 17px #0abbb5;
}
.other-services__right__container {
  flex-basis: 30%;
  display: flex;
  padding: 5vh 5vw;
  flex-direction: column;
}
.right-container__title {
  font-size: 5vh;
  font-weight: bolder;
  text-align: center;
}
.other-services__block {
  display: flex;
  flex-basis: 30%;
  flex-direction: column;
  justify-content: flex-end;
}
.other-service-icon__container {
  display: flex;
  justify-content: center;
}
.other-serives__texts {
  padding: 2vh 2vw 0 2vw;
}
.other-serices__title {
  text-align: center;
  font-size: 2.2vh;
  font-weight: bold;

}
.other-serices__subtitle {
  text-align: center;
  font-size: 2vh;
  font-weight: 200;
}

//////////////////////// Media ////////////////////////
.media__container {
  margin-top: 5vh;
  padding: 5vh 20vw;
  background: #F6F6F6;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .icon {
    background: url('/img/landingBody/news.png') no-repeat 0 0;
    height: 6vh;
    cursor: pointer;
  }
  .tvw-icon {
    width: 90px;
    background-position: 0 0;
  }
  .tech-crunch-icon {
    width: 146px;
    background-position: -121px 0;
  }
  .cnbc-icon {
    width: 62px; 
    background-position: -430px 0;
  }
  .forbes-icon {
    width: 108px; 
    background-position: -525px 0;
  }
  .cnn-icon {
    width: 70px; 
    background-position: -666px 0;
  }
}

//////////////////////// Footer ////////////////////////
.footer__container--desktop {
  background: black;
  height: 30vh;
  padding: 7vh 7vh 2vh 7vh;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.footer-logo {
  color: white;
  font-size: 4.3vh;
}
.footer-links__container {
  display: flex;
  justify-content: flex-start;
}

.links-title {
  cursor: pointer;
  font-size: 2vh;
  font-weight: 500;
  color: white;
  flex-basis: 14%;
}
