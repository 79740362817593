//////////////////////// Right Moving Company ////////////////////////
.body-section-padding {
	padding: 25px 15px;
}
.right-moving-company--mobile {
	background: #f6f6f6;
}
.body-content-title__container {
	text-align: center;
}
.body-content__title {
	font-weight: bolder;
	font-size: 3.5vh;
}
.body-content__subtitle {
	font-size: 1rem;
	line-height: 20px;
	font-weight: 300;
}
.right-moving-company__row {
	display: flex;
	justify-content: space-between;
}
.right-moving-company__block {
	flex-basis: 30%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	padding: 5vh 0;
	.img__container {
		height: 50px;
	}
	img {
		width: 35px;
		height: 40px;
	}
	.truck-globe {
		width: 41px !important;
	}

	.contents {
		font-size: 0.9rem;
		line-height: 18px;
		text-align: center;
		font-weight: 300;
		letter-spacing: -0.4px;
	}
}
.contents__title {
	font-weight: bolder;
	font-size: 2.2vh;
	text-align: center;
	margin-bottom: 0.8vh;
}

.power-by-unpakt__container {
	display: flex;
	justify-content: center;
	img {
		height: 4vh;
	}
}

//////////////////////// How Flatrate Works ////////////////////////
.how-unpakt-works__block {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 2vh 0;
}
.how-unpakt-words__block__split {
	position: relative;
	display: flex;
	justify-content: center;
	padding: 3.5vh 0 0 0;
	img {
		margin-bottom: 2vh;
	}
}
.how-move-now-works {
	font-weight: bolder;
	font-size: 2.2vh;
	margin-bottom: 0.8vh;
}
.circle-line--white {
	position: absolute;
	border-radius: 50%;
	height: 10px;
	width: 10px;
	border: 1px solid rgb(194, 194, 194);
	background: white;
}
.how-unpakt-words__block--left {
	border-right: 1px solid rgb(194, 194, 194);
	flex-basis: 45%;
	padding-right: 5vw;
	text-align: -webkit-right;
}
.how-unpakt-words__block--right {
	flex-basis: 45%;
	padding-left: 5vw;
	text-align: -webkit-left;
}
.circle-line--grey {
	position: absolute;
	border-radius: 50%;
	height: 10px;
	width: 10px;
	border: 1px solid rgb(194, 194, 194);
	background: rgb(194, 194, 194);
	bottom: 0;
}
.content-button__container {
	display: flex;
	justify-content: center;
}
.content-button--white {
	border: 2px solid black;
	padding: 15px 35px;
	background: white;
	font-weight: 500;
	font-size: 2.7vh;
	color: black;
}
.content-button--black {
	border: 2px solid black;
	padding: 15px 35px;
	background: black;
	font-weight: 500;
	font-size: 2.7vh;
	color: white;
}

//////////////////////// Don't just take our word for it ////////////////////////
.quote-star__container {
	display: flex;
	justify-content: space-between;
	img:nth-child(1) {
		flex-basis: 20%;
		height: 8vh;
	}
	img:nth-child(2) {
		margin-top: 6vh;
		flex-basis: 50%;
	}
	.empty {
		flex-basis: 20%;
	}
}
.name-quote__container {
	display: flex;
	justify-content: space-between;
	.empty {
		flex-basis: 20%;
	}
	.name {
		text-align: center;
		p {
			margin-bottom: 0;
			font-weight: 600;
		}
		flex-basis: 50%;
	}
	img {
		flex-basis: 20%;
		height: 8vh;
		margin-top: 4vh;
	}
}
.user-words__container {
	padding: 32px 35px 10px 35px;
	p {
		font-style: italic;
		line-height: 21px;
		font-weight: 200;
	}
}

//////////////////////// Big Half Circle ////////////////////////
.big-half-circle {
	background: #f6f6f6;
	padding: 17vh 5vh;
	img {
		width: 7.8vh;
		margin-bottom: 1rem;
	}
	&.left {
		border-radius: 300px 0 0 300px;
	}
	&.right {
		border-radius: 0 300px 300px 0;
	}
}
.half-circle__block {
	padding: 2vh 3vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: 2vh 0;
}
.half-circle__text {
	font-size: 2.3vh;
	font-weight: 200;
	text-align: center;
	line-height: 20px;
}

//////////////////////// Logos container ////////////////////////
.logos__container {
	margin: 5vh 0;
	padding: 0 2.5vw;
}
.logos__row {
	margin-bottom: 3.3vh;
	display: flex;
	justify-content: space-evenly;
	align-items: flex-end;
	.zipcar,
	.havenly {
		flex-basis: 25%;
		height: 5.2vh;
	}
	.lofty {
		flex-basis: 25%;
		height: 3.6vh;
	}
	.maker-space {
		flex-basis: 35%;
		height: 4vh;
	}
	.liberty_mutual {
		flex-basis: 35%;
		height: 4.6vh;
	}
}

//////////////////////// Footer ////////////////////////
.landing-footer__container {
	margin-top: 6vh;
	height: 50vh;
	background: black;
	color: white;
	display: flex;
	justify-content: space-between;
	padding: 8vh 4vh;
}
.footer-column {
	display: flex;
	flex-direction: column;
}
.footer-column__title {
	font-size: 2.7vh;
	font-weight: bolder;
}
.footer-column__list {
	list-style: none;
	padding-left: 0;
	li {
		font-weight: 500;
		margin-bottom: 1.2vh;
		letter-spacing: -0.6px;
	}
}
