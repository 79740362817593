.passwords-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-left: 15px;
}

@media (min-width: 1024px) {
  .passwords-wrapper {
    flex-direction: row;
  }
}
