/*!
 * Bootstrap v3.3.7 (http://getbootstrap.com)
 * Copyright 2011-2016 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 *
 * UNPAKT: https://github.com/twbs/bootstrap-sass#sass
 * See these instructions for information on this file.
 */

// Core variables and mixins
// @import "./node_modules/bootstrap-sass/assets/stylesheets/bootstrap/variables";

// overrides
$font-family-sans-serif: Montserrat !default;
$link-color:                 #0073bb; //red
$link-hover-color:           #0073bb; //green

// @import "./node_modules/bootstrap-sass/assets/stylesheets/bootstrap/mixins";

// // Reset and dependencies
// @import "./node_modules/bootstrap-sass/assets/stylesheets/bootstrap/normalize";
// //@import "./node_modules/bootstrap-sass/assets/stylesheets/bootstrap/print";
// //@import "./node_modules/bootstrap-sass/assets/stylesheets/bootstrap/glyphicons";

// // Core CSS
// @import "./node_modules/bootstrap-sass/assets/stylesheets/bootstrap/scaffolding";
// @import "./node_modules/bootstrap-sass/assets/stylesheets/bootstrap/type";
// //@import "./node_modules/bootstrap-sass/assets/stylesheets/bootstrap/code";
// @import "./node_modules/bootstrap-sass/assets/stylesheets/bootstrap/grid";
//@import "./node_modules/bootstrap-sass/assets/stylesheets/bootstrap/tables";
//@import "./node_modules/bootstrap-sass/assets/stylesheets/bootstrap/forms";
//@import "./node_modules/bootstrap-sass/assets/stylesheets/bootstrap/buttons";

// Components
// @import "bootstrap/component-animations";
// @import "bootstrap/dropdowns";
// @import "bootstrap/button-groups";
// @import "bootstrap/input-groups";
// @import "bootstrap/navs";
// @import "bootstrap/navbar";
// @import "bootstrap/breadcrumbs";
// @import "bootstrap/pagination";
// @import "bootstrap/pager";
// @import "bootstrap/labels";
// @import "bootstrap/badges";
// @import "bootstrap/jumbotron";
// @import "bootstrap/thumbnails";
// @import "bootstrap/alerts";
// @import "bootstrap/progress-bars";
// @import "bootstrap/media";
// @import "bootstrap/list-group";
// @import "bootstrap/panels";
// @import "bootstrap/responsive-embed";
// @import "bootstrap/wells";
// @import "bootstrap/close";

// Components w/ JavaScript
// @import "bootstrap/modals";
// @import "bootstrap/tooltip";
// @import "bootstrap/popovers";
// @import "bootstrap/carousel";

// Utility classes
// @import "./node_modules/bootstrap-sass/assets/stylesheets/bootstrap/utilities";
// @import "./node_modules/bootstrap-sass/assets/stylesheets/bootstrap/responsive-utilities";
