.tabs-stretch {
  .list-inline {
    font-size: 0; // remove residual space between list items by setting newline char font
    margin-left: 0;
    li {
      display: inline-block;
    }
  }
  @include grandparent(".inventory-edit-view") {
    @import "variables/moving";
    .tab {
      border-bottom: 1px solid $panel-border-color;

      font-size: 12px;
      background: inherit;
      padding: 5px 0;
      font-weight: 600;
      font &:hover,
      &:focus {
        text-decoration: none;
      }
      &:first-of-type {
        width: 33%;
        border-right: 1px solid #d6d6d6;
        border-right-width: 1px;
        border-right-style: solid;
        border-right-color: rgb(214, 214, 214);
      }
      &:nth-of-type(2) {
        width: 33%;
        border-right: 1px solid #d6d6d6;
        border-right-width: 1px;
        border-right-style: solid;
        border-right-color: rgb(214, 214, 214);
      }
      &:nth-of-type(3) {
        width: 33%;
        border-right: 1px solid #d6d6d6;
      }
      span,
      svg {
        vertical-align: middle;
      }
    }
    .tab-active {
      a {
        color: #333333;
      }
    }
  }
  @include grandparent(".book-view") {
    @import "variables/moving";
    .tab {
      border-bottom: 1px solid $panel-border-color;
      width: 50%;
      display: none;
      color: $black;
      font-size: 12px;
      margin-top: 2px;

      &:hover,
      &:focus {
        text-decoration: none;
      }
    }
    .tab-active {
      button {
        color: #333333;
      }
    }
  }
}

.tabs-scroll {
  @include grandparent(".inventory-view-wrapper") {
    @import "variables/moving";
    overflow-x: scroll;
    width: auto;
    white-space: nowrap;
    border-bottom: 1px solid $panel-border-color;
  }
}
.tab {
  position: relative;
  outline: none !important;
  .icon {
    position: absolute;
    width: 34px;
    height: 34px;
    top: -3px;

    &.my-inventory {
      left: -4px;
      top: -2px;
    }
    &.add-items {
      left: -4px;
      top: -2px;
    }
    &.search {
      left: 0;
    }
    &.vertical-ellipse {
      left: 0;
    }
    &.close {
      left: -8px;
      fill: black;
    }
  }
  svg {
    position: absolute;
    fill: inherit;
    left: -2%;
  }
  vertical-align: middle;
  button {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    color: $text-dark-gray;
    &,
    &:hover,
    &:focus {
      text-decoration: none;
    }
  }
  &.tab-active {
    fill: #f7801e !important;
  }
}
.tab-icon {
  svg {
    height: 35px;
    width: 35px;
    margin: auto;
  }
  img {
    width: 30px;
    height: 30px;
  }
  button {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    font-size: 14px;
    > * {
      display: block;
    }
  }
}
.tabs-primary {
  @include grandparent(".inventory-view-wrapper, .book-view") {
    @import "variables/moving";
    .tab {
      height: 32px;
      border-right: 1px solid $input-border-color;
      &:not(.tab-active) {
        padding-bottom: 1px;
      }
      &:last-of-type {
        border-right: none;
      }
      a {
        line-height: 32px;
        font-weight: 600;
        > img {
          width: 30px;
          vertical-align: top;
        }
        > img,
        > span {
          display: inline-block;
        }
      }
    }
    .tab-active {
      border-left-color: #f7801e !important;
      border-right-color: #f7801e !important;
    }
  }
}

.tab-dropdown {
  @include grandparent(".inventory-view-wrapper, .book-view") {
    position: relative;
    .dropdown-list {
      position: absolute;
      top: 100%;
      right: 0;
      display: block;
      border: 1px solid $input-border-color;
      border-bottom: none;
      border-radius: 1px;
      box-shadow: 0 0 1px $input-border-color;
      background-color: #fafafa;
      z-index: 5;
      li {
        border-bottom: 1px solid $input-border-color;
      }
      a {
        line-height: 1;
        font-size: 14px;
        white-space: nowrap;
      }
    }
  }
}

.tab-pill {
  border: 1px solid $text-light-gray;
  border-radius: 3px;
  a {
    letter-spacing: -0.025em;
    font-size: em(14px);
  }
  &.tab-active {
    background-color: #333333;
    border-color: $red;
    border-color: #333333;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
    background-color: #333333;
    background: linear-gradient(#d90007, #c91400);
    a {
      color: white;
    }
  }
}
