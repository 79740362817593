.my-home-container .move-history {
    padding-top: 70px;
    display: flex;
    background: #fff;
    position: relative;
  }
  
  /* .move-history .move-plan-wrapper .card-format .d-inline-block { 
      display: flex; 
      flex-direction: column; 
      flex-wrap: nowrap; 
      align-content: normal; 
      justify-content: normal; 
      align-items: normal; 
      //margin-right: -15px; //margin-left: -15px;
  } */

  .card-format {
    border-radius: 2px;
    box-shadow: 0 0 20px #0000002e;
    border-top: 5px solid $themeGreen;
    background: #fff;
    display: flex; 
      flex-direction: column; 
      flex-wrap: nowrap; 
      align-content: normal; 
      justify-content: normal; 
      align-items: normal; 
  }
  
  iframe {
    border: none;
  }
  
  .link {
    color: #f7801e;
    cursor: pointer;
  }
  
  .link:hover {
    border-bottom: 1px solid #f7801e;
    color: #f7801e;
  }
  .primary-btn-small {
    vertical-align: middle;
    text-align: center;
    padding: 4px 30px;
    background: #f57c14;
    color: #fff!important;
    border: 1px solid #df7804;
    border-radius: 3px;
    cursor: pointer;
    font-family: ProximaNovaRegular,sans-serif;
    -webkit-font-smoothing: antialiased;
    white-space: nowrap;
    max-height: 34px;
    font-size: 1.6rem;
  }
  
  .primary-btn-small:hover, .primary-btn-small:active {
    background: #fe931b;
  }
  
  .text-format h2 {
    font-size: 2.8rem;
    font-weight: 700;
    font-family: "ProximaNovaBold", sans-serif;
  }
  .text-format h3 {
    font-size: 1.7rem;
    font-family: "ProximaNovaBold", sans-serif;
  }
  .text-format h4 {
    font-size: 1.5rem;
    font-family: "ProximaNovaRegular", sans-serif;
  }
  .text-format h5 {
    font-size: 1.4rem;
    font-family: "ProximaNovaBold", sans-serif;
  }
  
  .text-format.mobile h2 {
    font-size: 1.8rem;
    line-height: 2rem;
  }
  .text-format.mobile h3 {
    font-size: 1.2rem;
  }
  .text-format.mobile h4 {
    font-size: 1.2rem;
  }
  .text-format.mobile h5 {
    font-size: 1.2rem;
  }
  
  .iframe-holder {
    position: fixed;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    -webkit-transform: translate3d(0,0,0);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .iframe-holder iframe {
    height: 100vh;
    width: 100%;
    *width: 100%;
    margin-bottom: -4px;  
  }
  
  .custom-error {
    color: red;
    margin-top: -1rem;
    font-size: 1rem;
    margin-bottom: .5rem;
  }
  .field-error {
  border: 1px solid red!important;
  background: rgba(255,0,0,.1)!important;
  color: red!important;
  }
  

  .button-spinner{
    svg {
      width: 2.8rem !important;
    }
  }
  .loading-icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 200px;
  }