.review-pricing-subtitle {
    font-size: 14px;
    letter-spacing: -0.7px;
}

.pricing-row {
    display: flex;
    justify-content: space-between;
    letter-spacing: -0.7px;
}

.total-price-row {
    border-top: 1px solid $borderGrey;
    margin-top: 12px;
    padding-top: 12px;
    font-weight: bolder;
}