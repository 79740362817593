$datepicker__background-color: #f0f0f0;
$datepicker__border-color: #aeaeae;
$datepicker__highlighted-color: #3dcc4a;
$datepicker__muted-color: #ccc;
$datepicker__selected-color: #216ba5;
$datepicker__text-color: #000;
$datepicker__header-color: #000;
$datepicker__navigation-disabled-color: lighten($datepicker__muted-color, 10%);

$datepicker__border-radius: 0.3rem;
$datepicker__day-margin: 0.166rem;
$datepicker__font-size: 0.8rem;
$datepicker__font-family: "Helvetica Neue", Helvetica, Arial,
  sans-serif;
$datepicker__item-size: 1.7rem;
$datepicker__margin: 0.4rem;
$datepicker__navigation-size: 0.45rem;
$datepicker__triangle-size: 8px;