.flex-row {
	width: 100%;
	display: flex;
	justify-content: center;
}

.DnaForm-Top-container {
	height: 30vh;
	display: flex;
	align-items: center;
	margin-top: 15px;
	flex-direction: column;
}

.dna-title {
	font-size: 20px;
	font-weight: 1000;
	margin-bottom: 20px;
}

.DnaForm-Top-container-toggled-extra {
	height: 35vh;
}

@keyframes flyIn {
	0% {
		top: -50px;
		opacity: 0;
	}
	100% {
		top: 0;
		opacity: 1;
	}
}

.address-container {
	flex-basis: 80%;
	display: flex;
}

.swap-container {
	flex-basis: 10%;
	display: flex;
	align-items: center;
	justify-content: center;
	padding-bottom: 65px;
}

.address-panel {
	display: flex;
	flex-direction: column;
	flex-basis: 100%;
}
.address-row {
	display: flex;
	justify-content: flex-start;
	background: white;
	margin-bottom: 16px;
	min-height: 40px;
}

.address-row-slider {
	margin-bottom: 2px;
}

.address-column-1 {
	min-width: 18px;
	width: 5%;
	min-height: 100%;
}
.address-column-2 {
	min-width: 50px;
	width: 15%;
	margin-left: 5px;
	min-height: 100%;
}
.address-column-3 {
	min-width: 130px;
	width: 60%;
	min-height: 100%;
}

.address-column-4 {
	min-width: 40px;
	width: 20%;
	min-height: 100%;
}
.extra-stop-switch-container {
	flex-basis: 70%;
	margin-bottom: 0;
}
.back-btn-container {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-basis: 12%;
	border: none;
	background: white;
	outline: none !important;
	padding: 0;
}
.address-text {
	flex-basis: 13%;
	margin-bottom: 0px;
	align-self: center;
	font-size: 17px;
	color: grey;
	font-weight: 600;
	align-self: center;
}

.empty-row-btw-address-row {
	height: 12%;
	display: flex;
	justify-content: flex-start;
}
.dots-container {
	display: flex;
	flex-direction: column;
	flex-basis: 10%;
	justify-content: space-evenly;
	align-items: center;
}

.DnaRow-geosuggest-div {
	border: 1px solid $lightBorderGrey;
	flex-basis: 55%;
	position: relative;
}
.address-apt {
	flex-basis: 15%;
	margin-left: 10px;
	min-height: 100%;
	input {
		min-height: 40px;
		text-align: center;
	}
}
.blue-border__apt {
	input {
		border: 1.5px solid #0073bb !important;
		box-shadow: 1px 1px 10px #007bff;
	}
}
.blue-border {
	border: 1.5px solid #0073bb !important;
	box-shadow: 1px 1px 10px #007bff;
}
.DnaRow-extra-geosuggest-div {
	border: 1px solid #eeeeee;
	flex-basis: 55%;
	position: relative;
}
.DnaRow-geosuggest {
	height: 37px;
}

.back-btn{
	position: absolute;
    z-index: 1;
    // padding: .5em 0.2em 0.5em 1em;
	display: flex;
    padding: 6px 2px 6px 10px;
    border-radius: 50%;
    border: initial;
    // box-shadow: 3px 3px 3px rgba(0, 0, 0, .3);
	box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.3);
    left: 15px;
    background: white;
	@media (max-width : 560px){
		top: 15px;
	}
	top:1.5%;
	>.MuiSvgIcon-root{
		color:rgb(25, 25, 25);
		width: .95rem;
		height: .95rem;
	}
}

.continue-btn {
	height: 8%;
	position: fixed;
	background-color: $themeGreen;
	width: 100%;
	display: flex;
	bottom: 0;
	justify-content: center;
	align-items: center;
	margin-top: 10px;
	border: none;
	z-index: 1;
	p {
		color: white;
		font-size: 21px;
		margin: 0;
	}
}

.continue-btn-fixed {
	bottom: 0;
	display: flex;
}

.continue-btn :disabled,
.continue-btn[disabled] {
	background-color: grey;
}

.move-date-container {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-basis: 19%;
	border-top: 1px solid rgb(220, 220, 220);
}

.date-pick-input-class {
	border: none !important;
	font-weight: bolder;
	letter-spacing: -0.6px;
	font-size: 18px;
}

.move-date-svg {
	flex-basis: 10%;
	margin: 0px 5px;
}
.move-date-label {
	letter-spacing: -0.7px;
	margin-bottom: 0;
	margin-top: 3px;
	flex-basis: 40%;
	font-size: 18px;
	font-weight: 600;
}
.date-picker-container {
	flex-basis: 43%;
	margin-top: 2.8px;
}
.move-date-edit {
	flex-basis: 7%;
}

.prof-packing-info-container.typicals-text{
	flex-direction: row; 
}
.prof-packing-info-container.typicals-text span{
	width: 240px;
}

.icon-container .move-date-label{
	flex-basis: 39%;
}

@media (min-width: 561px){
	.typicals-text{
		width:190px
	}
	.prof-packing-info-container.typicals-text span{
		width: initial;
	}
}

@media (max-width: 350px) {
	.date-picker-container {
		margin-top: 2px;
	}
	.date-pick-input-class {
		font-size: 16px;
	}
	.move-date-label {
		font-size: 16px;
	}
}

@media (max-width: 560px) {
	a.carousel-control-prev, a.carousel-control-next{
		padding: 50px 0 0 0;
		align-items: initial;
	}
	img.carousel-image {
		height: 85px;
		width: 85px;
	}
	.carousel-clicked img.carousel-image{
		height:90px;
		width:90px
	}
	.carousel-clicked .carousel-captions h3{
		font-size: 18px;
	}
	.carousel-clicked .carousel-captions p{
		font-size: 12px;
	}

	.move-date-label{
		font-size: 15px;
	}
	.move-date-container.inventory-container{
		display: block;
		padding: 8px;
	}
	.icon-container{
		display: flex;
		justify-content: center;
	}
	.icon-container .move-date-label{
		flex-basis: initial;
	}
	
}


.get-moving-btn {
	flex-basis: 15%;
	letter-spacing: -0.4px;
	background-color: $themeGreen;
	border: none;
	p {
		color: white;
		font-size: 21px;
		margin: 0;
	}
}

.carousel-control-prev-icon {
	background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%2312BEEB' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E") !important;
}
.carousel-control-next-icon {
	background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%2312BEEB' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E") !important;
}
.carousel-container.embed .carousel-control-prev-icon{
	background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%233D3D3D' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E") !important;
}
.carousel-container.embed .carousel-control-next-icon {
	background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%233D3D3D' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E") !important;
}

.bottom-safari {
	height: 0.1px;
	position: relative;
	bottom: -40px;
}

.react-datepicker__header {
	border-bottom-color: #efefef;
	background-color: white;
}
.react-datepicker__week {
	background-color: white;
}
.react-datepicker__current_month {
	background: white;
	font-size: 1rem;
}
.react-datepicker__day-names {
	background-color: white;
}
.react-datepicker__day-name {
	color: orange;
	text-transform: uppercase;
}
.react-datepicker__day--selected {
	background-color: #4eb5e6;
}

.react-datepicker__portal .react-datepicker__day-name,
.react-datepicker__portal .react-datepicker__day,
.react-datepicker__portal .react-datepicker__time-name {
	font-family: 'Roboto';
	font-weight: 500;
}
.react-datepicker__portal .react-datepicker__current-month,
.react-datepicker__portal .react-datepicker-time__header {
	font-size: 1rem;
}

.inner-input-error {
	border: 1.5px solid #ff9494 !important;
	background: rgba(255, 0, 0, 0.1);
	height: 38px;
}
.extra-address-label-row {
	display: flex;
	justify-content: center;
	flex-basis: 70%;
	margin-bottom: 0;
}
