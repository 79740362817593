.loader-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 65vh;
}
.powered-by-logo {
    background: url('/img/PoweredBy_White_v2.svg') no-repeat;    
    height: 25px;
    width: 50px;
    position: absolute;
    right: 0;    
    bottom: 0;
}