.billing-container {
    display: flex;
    flex-direction: column;
    border-top: 1px solid $borderGrey;
    padding-top: 15px;
}
.billing-row {
    display: flex;
    margin-bottom: 15px;
    input {
        flex-basis: 50%;
        margin-left: 5px;
        margin-right: 5px;
    }
    .billing-card-container {
        flex-basis: 70%;
        margin-left: 5px;
        margin-right: 5px;
    }
    .billing-cvv-container {
        flex-basis: 30%;
        margin-left: 5px;
        margin-right: 5px;
    }
    .expire-info {
        flex-basis: 33%;
        margin-left: 5px;
        margin-right: 5px;
    }
    .billing-zip {
        flex-basis: 33%;
        margin-left: 5px;
        margin-right: 5px;
    }
}

.contact-info-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}
.contact-info-row {
    display: flex;
}