.home-date-container {
	height: 45vh;
	position: fixed;
	bottom: 0px;
	width: 35%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	background: white;
	z-index: 1;
	transition: height 0.3s linear;
}
.home-date-container-selected-homesize {
	height: 60vh !important;
}

@media (max-width: 560px) {
	.home-date-container-selected-homesize.embed{
		height: 65vh !important;
	}
}

@media (max-width: 600px) {
    .home-date-container {
        width: 100%;
    }
}

// iPhone 6/7/8 plus, iPhone X/11+
@media only screen and (min-height: 706px) {
	.home-size-title-container {
		flex-basis: 25% !important;
		align-items: center;
	}
	.carousel-container {
		flex-basis: 75% !important;
	}
}
.home-size-container {
	flex-basis: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
}
.home-size-container-selected-homesize {
	flex-basis: 66% !important;
}
.home-size-title-container {
	flex-basis: 10%;
	display: flex;
	justify-content: center;
	p {
		font-size: 23px;
		font-weight: bolder;
		color: black;
		margin-top: 0.2rem;
		margin-bottom: 0
	}
}

.carousel-container {
	flex-basis: 90%;
}
.carousel-inner {
	height: 100%;
}
.hide-arrows .carousel-control-prev,
.hide-arrows .carousel-control-next,
.hide-arrows .carousel-indicators {
	display: none;
}
.home-date-container.embed .carousel-control-prev-icon, 
.home-date-container.embed .carousel-control-next-icon {
	position: relative;
	top: -30px;
}

@media (max-width: 560px) {
	.home-date-container.embed .carousel-control-prev-icon, 
	.home-date-container.embed .carousel-control-next-icon {
		top: 40px;
	}
}

// .carousel-item.is-embed,
.carousel-item.active,
.carousel-item.is-embed.active,
.carousel-item-next, .carousel-item-prev{
	display: flex;
	justify-content: center;
}

.carousel-indicators li {
	width: 5px;
	height: 5px;
	background-color: gray;
	border-radius: 50%;
}

.carousel-indicators li.active{
	background-color: black;
}


// .carousel-item.is-embed:not(.active){
// 	display: none;
// }
.home-size-carouselItem,
.home-size-carouselItem.is-embed{
	height:fit-content;

	.select-cta__container{
		border: 2px solid;
		border-radius: 30px;
		width: 85px;
		height: 35px;
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 0 auto 1rem;
		p {
			margin-bottom: 0;
		}
	}
}

// .carousel-item.is-embed:not(.active){
// 	display: none;
// }

.home-size-carouselItem.is-embed.carousel-clicked{
	height:8.5em
}

.home-size-carouselItem {
	// height: 9.5em;
	.d-none {
		display: block !important;
		color: black;
	}

	button {
		background: transparent;
		padding: 0 1rem;
		border: initial;
		border-radius: 10px;
		box-shadow: 0px 4px 15px rgba(0, 0, 0, .3);
		position: relative;
		transition: all 0.1s linear;
	}
	img {
		transition: all 0.1s linear;
	}
	.carousel-captions h3, p {
		transition: all 0.1s linear;
	}
	.select-cta__container{
		transition: all 0.1s linear;
	}
	.cancel-selection__homeSize{
		display: none
	}
	
}
.carousel-clicked {
	button{
		box-shadow: initial;
	}
	img {
		height: 127px;
    	width: 127px;
	}
	.carousel-captions h3 {
		font-size: 21px;
		color: $themeGreen;
	}
	.carousel-captions p {
		font-size: 13px;
		font-weight: bolder;
		color: $themeGreen;
	}
	.select-cta__container{
		display: none !important;
	}
	.cancel-selection__homeSize{
		display: block;
		position: absolute;
		right: -10px;
		top: 0px;
		z-index: 2;
		box-shadow: initial;
	}
}

.carousel-img-container {
	display: flex;
	justify-content: center;
}
.carousel-image {
	height: 120px;
	width: 120px;
}

// Prevent home size subtitle from being covered by datepicker
@media (max-width: 330px) {
	.carousel-image {
		height: 100px;
		width: 100px;
	}
	.carousel-clicked {
		img {
			height: 104px;
			width: 104px;
		}
		.carousel-captions h3 {
			font-size: 20.7px;
			color: $themeGreen;
		}
		.carousel-captions p {
			font-size: 12.7px;
			font-weight: bolder;
			color: $themeGreen;
		}
	}
}

.carousel-captions {
	position: initial !important;
	padding: 0px;
	h3 {
		font-size: 20px;
		color: black;
		font-weight: bolder;
		margin-bottom: 0px;
	}
	p {
		font-size: 12px;
		color: black;
	}
}