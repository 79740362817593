.review-edit-btn-container {
    display: flex;
}
.review-edit-btn {
    color: $themeGreen;
    border: none;
    background-color: white;
    letter-spacing: -0.7px;
    font-weight: 600;
}
.review-edit-icon-container {
    display: flex;
    align-items: center;
}

.details-row-container {
    display: flex;
    letter-spacing: -1px;
    height: 32px;
    line-height: 1.2;
    p {
        margin-bottom: 0px;
        font-size: 14px !important;
    }
}

.update-height-loading-bar {
    margin-top: 4px;
    margin-bottom: 4px;
    margin-right: 25px;
}
.review-details-address-text-container {
    display: flex;
    align-items: center;
    flex-basis: 91%;
    flex-wrap: wrap;
}

.review-details-icon {
    width: 35px;
    fill: #89959D;
    flex-basis: 9%;
}

.review-datetime-miles-container {
    display: flex;
    margin-bottom: 14px;
    margin-top: 15px;
    justify-content: space-between;
    p {
        margin-bottom: 0px;
    }
}

.review-datetime-container {
    margin-left: 4px;
}

.review-move-date-container {
    display: flex;
    align-items: center;
}
.details-row-datetime-container {
    display: flex;
    letter-spacing: -1px;
    height: 30px;
}
.review-move-time-container {
    padding-left: 29px;
}
.review-miles-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    line-height: 16px;
}

.review-miles {
    font-size: 24px !important;
    font-weight: 600;
    letter-spacing: -0.7px;
}

button.review-continue-fixed-position {
    background-color: #0abbb5;
    border: none;
    color: white;
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    height: 8%;
    font-size: larger;
}

.disassembly-service-btn {
    background-color: $themeGreen;
    border: none;
    color: white;
    font-size: larger;
    width: 100%;
    margin-top: 22px;
    margin-bottom: 12px;
    letter-spacing: -0.6px;
    height: 36px;
}

.panel-heading-packing-service {
	background-color: $themeGreen;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 36px;
}

.panel-title-packing-service {
	position: relative;
	cursor: pointer;
	font-size: larger;
	color: white;
    flex: 1;
    text-align: center;
	letter-spacing: -0.6px;
	a {
		color: $black;
		&:hover, &:focus {
			text-decoration: none;
		}
	}
	&::before,
	&::after {
		content: '';
		display: block;
		height: 8px;
		position: absolute;
		right: 0px;
		top: 0;
		transition: all 0.3s ease;
		width: 8px;
	}
	&::after {
		border-right: solid 2px;
		margin-top: 5px;
		top: 0;
		transform: rotate(-45deg);
		transform-origin: center;
	}
	&::before {
		border-left: solid 2px;
		margin-top: 5px;
		top: 0;
		transform: rotate(45deg);
		transform-origin: center;
	}
	&.collapsed {
		&::before {
			top: 0;
			transform: rotate(-45deg);
		}
		&::after {
			top: 0;
			transform: rotate(45deg);
		}
	}
}

.prof-packing-container {
    display: flex;
    padding: 10px 5px 0px 10px;
}
.prof-packing-radio-container {
    display: flex;
    align-items: center;
}
.prof-packing-info-container {
    display: flex;
    flex-direction: column;
    margin-left: 15px;
    line-height: normal;
}
.prof-packing-info-text {
    margin-bottom: 0;
    letter-spacing: -0.4px;
    font-weight: 300;
}

.grand-total-container {
    font-size: 16px;
    margin-left: 5px;
    display: flex;
    justify-content: space-between;
    .grand-total-text {
        align-self: center;
        margin-right: 12px;
        color: black;
    }
    span {
        font-size: 26px;
        letter-spacing: -0.7px;
        font-weight: 600;
    }
}

.price-bottom{
    justify-content: center;
    margin: 0;
}

.grand-total-description {
    margin: 3px 0px 5px 5px;
    letter-spacing: -0.7px;
    line-height: normal;
    font-size: 14px;
}

.hasmover-cost {
    letter-spacing: -0.6px;
    margin-bottom: 0;
    span {
        margin-left: 8px;
    }
}

.review-addition-services-container {
    color: grey;
    padding-left: 5px;
    span, p {
        margin-bottom: 0;
        font-size: 15px !important;
        letter-spacing: -0.6px;
    }
}

.panel-body-spacer {
    height: 192px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.review-coi {
    color: $themeGreen;
    font-size: 14px;
    font-weight: 600;
    text-decoration: underline;
    margin: 0px 0px 0px 25px;
    letter-spacing: -0.5px;
}

.see-more-prices-row {
    display: flex;
    justify-content: flex-end;
    p {
        color: grey;
        text-decoration: underline;
        margin-bottom: 0;
    }
}
.suggested-movers-row {
    display: flex;
    justify-content: flex-end;
}
.suggested-movers-container {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
}
.suggested-mover-card {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    border: 1px solid $themeGreen;
    border-radius: 8px;
    margin-bottom: 8px;
    p {
        letter-spacing: -0.6px;
        color: $themeGreen;
        margin-bottom: 0;
    }
    height: 70px;
}
.mover-logo-container {
    flex-basis: 15%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img {
        max-width: 21px;
    }
    p {
        font-size: 10px !important;
        line-height: 8px;
        text-align: center;
        margin-top: 4px;
    }
}
.mover-name-rating-container {
    flex-basis: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    line-height: 15px;
}
.mover-name-rating {
    font-size: 12.5px !important;
}
.mover-price-container {
    flex-basis: 15%;
    display: flex;
    span {
        font-weight: bold;
        font-size: 16px;
        color: $themeGreen;
    }
}
.rating-container {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    p:nth-child(1) {
        flex-basis: 15%;
    }
    p:nth-child(2) {
        flex-basis: 25%;
    }
}
.color-change {
    background-color: $themeGreen;
    p, span {
        color:white;
    }
}
.disable-click {
    pointer-events: none;
    filter: opacity(0.5);
}

.no-mover-modal {
    margin-top: 200px;
}
.modal-footer {
    border-top: none;
}