@import "spacing";
@import "typography";

@function up-to($list, $index) {
  $l: ();
  @each $e in $list {
    @if length($l) < $index {
      $l: append($l, $e, list-separator($list));
    }
  }
  @return $l;
}

@function parent($selector: null) {
  $selector: & !default;
  $selector: selector-parse($selector);
  $first-parent-selector: nth($selector, 1);
  @return nth($first-parent-selector, length($first-parent-selector));
}

@function without-parent($selector: null) {
  $selector: & !default;
  $selector: selector-parse($selector);
  $ancestors: ();
  @each $subselectors in $selector {
    $ancestors: append($ancestors, up-to($subselectors, length($subselectors) - 1), comma);
	}
  //@debug $ancestors;
  @return $ancestors;
}

@function null-selector($selector) {
  @if not $selector {
    @return true;
  }
  @else if length($selector) > 0 and length(nth($selector, 1)) > 0 {
    @return false;
  }
  @else {
    @return true;
  }
}

// Use this within an SCSS module to specify context-specific styling (e.g. `.specific-page .generic-module`)
@mixin grandparent($grandparents, $context: null) {
  $context: & !default;
  $grandparents: selector-parse($grandparents);
  $ancestors: without-parent($context);
  $selector: if(null-selector($ancestors), $grandparents, selector-nest($ancestors, $grandparents));
  $selector: selector-nest($selector, parent($context));
  @at-root #{$selector} {
    @content;
  }
}

.d-b {
	display: block !important;
}
.d-ib {
	display: inline-block !important;
}
.d-i {
	display: inline !important;
}

.w-100 {
	width: 100% !important;
}
.d-flex {
	display: flex !important;
}
.flex-33 {
	flex: 0 0 33.3333%;
}

.f-f-helvetica {
	font-family: 'HelveticaNeue';
}
