button.btn-continue-fixed-position {
    background-color: #0abbb5;
    border: none;
    color: white;
    width: 100%;
    position: fixed;
    left: 0;
    height: 8%;
    font-size: larger;
}


.btn-continue-price {
    font-size: 16px;
    position: absolute;
    right: 14px;
    margin-top: 3.5px;
}

.panel-heading {
	background-color: rgb(250, 250, 250);
}

.panel-title {
	position: relative;
	cursor: pointer;
	font-size: em(14px);
	font-weight: 600;
	a {
		color: $black;
		&:hover, &:focus {
			text-decoration: none;
		}
	}
	&::before,
	&::after {
		content: '';
		display: block;
		height: 8px;
		position: absolute;
		right: 24px;
		top: 0;
		transition: all 0.3s ease;
		width: 8px;
	}
	&::after {
		border-right: solid 2px;
		margin-top: 11px;
		top: 5px;
		transform: rotate(-45deg);
		transform-origin: center;
	}
	&::before {
		border-left: solid 2px;
		margin-top: 11px;
		top: 5px;
		transform: rotate(45deg);
		transform-origin: center;
	}
	&.collapsed {
		&::before {
			top: 0;
			transform: rotate(-45deg);
		}
		&::after {
			top: 0;
			transform: rotate(45deg);
		}
	}
}

.inventories-summary-container {
	display: flex;
	justify-content: space-between;
	margin-bottom: 5px;
	span {
		font-size: 16px;
		font-weight: 600;
		letter-spacing: -0.4px;
	}
}
.stickyOnTop {
	position: fixed !important;
	top: 70px;
	width: 100%;
	z-index: 1;
	color: black;
	background: #f2f2f2;
	height: 35px;
	align-items: center;
	left:0px;
	justify-content: space-around;
	box-shadow: 0px 0.2px 15px 0.1px #adadad;
}